import { LitElement, html, css } from 'lit';
import { formatWithOptions } from 'date-fns/fp';
import { enUS } from 'date-fns/locale';
import compose from 'crocks/helpers/compose';
import { PageMixin } from './page-mixin';
import { getAllEntries } from '../lib/db.js';
import buttonLinkStyles from '../shared-styles/button-link.css';
import pageStyles from '../shared-styles/page-styles.css';

const formatDateWithOptions = formatWithOptions(
  {
    awareOfUnicodeTokens: true,
    locale: enUS,
  },
  'd MMMM, yyyy, h:mm aaa'
);

const tryCatch =
  (fallback, f) =>
    (...args) => {
      try {
        return f(...args);
      } catch (error) {
      // console.error(error);
        return fallback;
      }
    };

/**
 * Formats an ISO date string as 'd MMMM, yyyy, h:mm a'
 * Ben's suggested method for date format
 * @type {Function}
 * @param {String} isoString ISO Date String
 * @return {String} Date formatted as 'd MMMM, yyyy, h:mm a'
 */
const prettyDate = tryCatch(
  'sometime...',
  compose(formatDateWithOptions, x => new Date(x))
);

const byISODate = (a, b) => {
  try {
    const aDate = new Date(a.date).getTime();
    const bDate = new Date(b.date).getTime();
    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
  } catch (error) {
    // console.error(error);
    return 0;
  }
};

class DeardiaryArchive extends PageMixin(LitElement) {
  static get is() {
    return 'deardiary-archive';
  }

  static get properties() {
    return {
      entries: { type: Array },
    };
  }

  static get styles() {
    return [
      pageStyles,
      buttonLinkStyles,
      css`
        li {
          width: calc(100vw - var(--page-padding) * 2);
        }

        ol {
          padding: 0;
          color: #ffffff;
          list-style-type: none;
        }

        summary {
          font-size: 22px;
          padding: 10px 0;
        }

        dt {
          font-family: var(--header-font);
        }

        dl {
          font-family: "Anonymous Pro", monospace;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.entries = [];
  }

  render() {
    const items = this.entries.sort(byISODate);
    return html` <h1>Archive</h1>
      <ol>
        ${items.map(
    ({ date, passage, question, answer }) => html` <li>
            <details>
              <summary><time>${prettyDate(date)}</time></summary>
              <dl>
                <dt>Passage</dt>
                <dd>${passage}</dd>
                <dt>Question</dt>
                <dd>${question}</dd>
                <dt>Answer</dt>
                <dd>${answer}</dd>
              </dl>
            </details>
          </li>`
  )}
      </ol>

      <br />

      <a id="files-button" class="button-link" href="/archivefiles"
        >Manage Your Files</a
      >

      <br />

      <a id="home-button" class="button-link" href="./">Home</a>`;
  }

  onActiveChanged(active) {
    if (active) this.getEntries();
  }

  async getEntries() {
    this.entries = await getAllEntries();
    this.entry = new URLSearchParams(location.search.substring(1)).get('date');
  }
}

customElements.define(DeardiaryArchive.is, DeardiaryArchive);
