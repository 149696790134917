export const PageMixin = superclass => class extends superclass {
  static get properties() {
    return {
      active: { type: Boolean, reflect: true },
    };
  }

  updated(changed) {
    if (changed.has('active')) {
      try {
        this.onActiveChanged(this.active);
      } catch (e) {
        throw new Error(`${this.constructor.is} must implement onActiveChanged`, e);
      }
    }
  }

  $(id) {
    return (this.shadowRoot ? this.shadowRoot : document).getElementById(id);
  }
};
