// DATABASE functions
import { get, set, keys } from 'idb-keyval';
import { sha1 } from './hash.js';

const getKey = key => get(key);

export const getAllEntries = async () =>
  await Promise.all((await keys()).map(getKey));

export const writeMultipleEntries = entries =>
  entries.forEach(writeSingleEntry);

/**
 * @typedef {Object} ArchiveEntry
 * @property {String} date
 * @property {String} passage
 * @property {String} question
 * @property {String} answer
 */

/**
 * Writes a single archive entry to idb
 * @param {ArchiveEntry} entry
 * @return {ArchiveEntry}
 */
export const writeSingleEntry = async ({ date, passage, question, answer }) => {
  const hash = await hashEntry({ date, passage, question });
  await set(hash, { date, passage, question, answer });
  return { date, passage, question, answer };
};

/**
 * Generates a hash of an entry to use as it's idb key
 * @param {ArchiveEntry} entry
 * @return {string}
 */
const hashEntry = ({ date, passage, question }) =>
  sha1(`${date}-${passage}-${question}`);
