
const bufferToHex = buffer => {
  const view = new DataView(buffer);

  let hexCodes = '';
  // eslint-disable-next-line no-loops/no-loops
  for (let i = 0; i < view.byteLength; i += 4) {
    hexCodes += view.getUint32(i).toString(16).padStart(8, '0');
  }

  return hexCodes;
};

const create = algorithm => async (buffer, options) => {
  if (typeof buffer === 'string') {
    buffer = new window.TextEncoder().encode(buffer);
  }

  options = { outputFormat: 'hex', ...options };

  const hash = await window.crypto.subtle.digest(algorithm, buffer);

  return options.outputFormat === 'hex' ? bufferToHex(hash) : hash;
};

export const sha1 = create('SHA-1');
export const sha256 = create('SHA-256');
export const sha384 = create('SHA-384');
export const sha512 = create('SHA-512');
