// import { ask } from './tellme.js';
import { installRouter } from "pwa-helpers";
import { writeValue } from "./storage";

import "./components/deardiary-archive";
import "./components/deardiary-archivefiles";
import "./components/deardiary-write";
import "./components/deardiary-question";
import "./components/deardiary-answer";
import "./components/deardiary-popup-modal";
import { $ } from "./lib/dom";

// Detects if device is on iOS
const isIos = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());

// Detects if device is in standalone mode
const isInStandaloneMode =
  "standalone" in window.navigator && window.navigator.standalone;

async function showIOSModal() {
  await import("./components/deardiary-popup-modal");
  const modal = document.getElementById("ios-modal");
  await modal.updateComplete;
  modal.opened = true;
}

// Checks if should display install popup notification:
if (isIos && !isInStandaloneMode) showIOSModal();

// Check that service workers are registered
if ("serviceWorker" in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/sw.js");
  });
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC78KCq-WPxWFtQLMG0MPBPtEv-2U_nm68",
  authDomain: "deardiarywtf-85cef.firebaseapp.com",
  projectId: "deardiarywtf-85cef",
  storageBucket: "deardiarywtf-85cef.appspot.com",
  messagingSenderId: "80146099030",
  appId: "1:80146099030:web:8e5789b2ff5e8d91b7c4f7",
  measurementId: "G-SLB58MEQGN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const mainContainer = document.querySelector("main");
const answer = document.getElementById("answer");
const write = document.getElementById("write");
const question = document.getElementById("question");

const deactivate = (element) => element.removeAttribute("active");
const activate = (element) => element.setAttribute("active", "");

const onResetQuestion = () => question.reset();
const onResetPassage = () => write.reset();
const onAnswerSubmitted = ({ detail: answer }) => {
  writeValue("answer", answer);
  // set browser's history so we don't break the back button
  history.pushState({ answer }, "answer page", "/write/answer");
  // trigger our router callback so that the app renders correctly.
  handleNavigation(window.location);
};

answer.addEventListener("reset-question", onResetQuestion);
answer.addEventListener("reset-passage", onResetPassage);
question.addEventListener("answer-submitted", onAnswerSubmitted);

/* ROUTING */

const handleNavigation = async (location) => {
  Array.from(mainContainer.children).forEach(deactivate);

  // If the user added a trailing slash, remove it from the pathname
  let { pathname } = location;
  if (pathname.endsWith("/")) pathname = pathname.slice(0, -1);

  switch (pathname) {
    case "/about":
      return activate($("about"));
    case "/archive":
      return activate($("archive"));
    case "/archivefiles":
      return activate($("archivefiles"));
    case "/privacy":
      return activate($("privacy"));
    // `/write` will fall through to `/write/passage`
    case "/write":
    case "/write/passage":
      return activate($("write"));
    case "/write/question":
      return activate($("question"));
    case "/write/answer":
      return activate($("answer"));
    // By default, handle cases where users are navigating to app
    // pages which don't make sense e.g. /write/answer when they
    // don't have a passage or a question, or a response, by routing to home.
    case "/":
    default: {
      question.reset();
      write.reset();
      return activate($("home"));
    }
  }
};

// Where the magic happens
installRouter((location, event) => {
  // Only scroll to top on link clicks, not popstate events.
  if (event && event.type === "click") window.scrollTo(0, 0);
  handleNavigation(location);
});

handleNavigation(location);
