import compose from 'crocks/helpers/compose';
import propPathOr from 'crocks/helpers/propPathOr';

/**
 * Takes a filename and a JS object and initiates a download through the browser
 * @param {String} filename
 * @param {any} object JSON serializable object
 * @return {undefined}
 */
export const downloadJson = (filename, object) => {
  const content = JSON.stringify(object, null, 2);
  const el = document.createElement('a');
  el.setAttribute('href', `data:application/json;charset=utf-8,${encodeURIComponent(content)}`);
  el.setAttribute('download', filename);
  el.hidden = true;
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

/**
 * Gets the `target.result` property from an event, or returns null
 * if it fails at any point
 * @type {Function}
 * @param {Event} event load Event
 * @return {File}
 */
const getFileResult = propPathOr(null, ['target', 'result']);

/**
 * Takes a file and reads it as JSON, resolving the JSON-parsed
 * file contents
 * @param {File} file
 * @return {Promise<[Object]>} Returns Promise of Array of Archive Entries
 */
export const readFileAsJson = file => {
  const reader = new FileReader();
  const promise = new Promise((resolve, reject) => {
    reader.onload = compose(resolve, JSON.parse, getFileResult);
    reader.onerror = reject;
  });
  reader.readAsText(file);
  return promise;
};

export const readFileListAsJson = files =>
  // Turn the file array into an array of promises of json,
  // then wait for them all to resolve
  // 3. traverse the [Promise] into Promise<Array>
  Promise.all(
    // 1. Convert FileList to Array
    Array.from(files)
      // 2. map readFileAsJson over the array to create [Promise]
      .map(readFileAsJson)
  )
    // todo: better error handling
    // eslint-disable-next-line no-console
    .catch(console.error);
