import { LitElement, html, css } from "lit";
import { PageMixin } from "./page-mixin";
import buttonLinkStyles from "../shared-styles/button-link.css";
import pageStyles from "../shared-styles/page-styles.css";
import { readValue, writeValue } from "../storage.js";
import { writeSingleEntry } from "../lib/db.js";
import { runQna } from "../qna.js";

// BELOW TO BE REORGANIZED BASED ON database, UI, network, utilities js modules

const updateDb =
  ({ passage, question }) =>
  (answer) =>
    writeSingleEntry({ date: new Date(), answer, passage, question });

async function fetchQuestion({ passage, question }) {
  if (!question) throw new Error("No Question");
  if (!passage) throw new Error("No Passage");
  return runQna(question, passage);
}

const handleError = (error) => console.error("More information needed."); // eslint-disable-line no-console

class DeardiaryQuestion extends PageMixin(LitElement) {
  static get is() {
    return "deardiary-question";
  }

  static get properties() {
    return {
      question: { type: String },
      passage: { type: String },
      loading: { type: Boolean },
      error: { type: Boolean },
    };
  }

  static get styles() {
    return [
      pageStyles,
      buttonLinkStyles,
      css`
        #submit {
          margin-top: 14px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.fireSubmitEvent = this.fireSubmitEvent.bind(this);
  }

  render() {
    return html`
      <input
        id="question-input"
        ?disabled="${!this.active || this.loading}"
        placeholder="What Do You Want to Know?"
        type="text"
        aria-labelledby="question-heading"
        name="question"
        .value="${this.question || ""}"
        @keyup="${this.onKeyup}"
      />
      <button id="submit" class="button-link" @click="${this.submit}">
        Press for Answer
      </button>
      <br />
      <img
        ?hidden="${!this.loading}"
        srcset="
          images/loaders/loader-120w.gif 120w,
          loader-81w.gif                  81w,
          loader-54w.gif                  54w
        "
        sizes="(max-width: 600px) 120px"
        src="loader-120w.png"
        alt="loading..."
      />

      <p style="text-align:center">
        <small ?hidden="${!this.error}"
          >More information needed. Please reword your question or go back and
          write more.<br /><a class="button-link" href="/write"
            >Go back</a
          ></small
        >
      </p>
    `;
  }

  onActiveChanged(active) {
    if (!active) return this.$("question-input").blur();
    this.question = readValue("question-input");
    this.passage = readValue("passage-input");
    this.$("question-input").focus();
    this.error = false;
  }

  onKeyup(event) {
    writeValue("question-input", event.target.value);
  }

  /* Fetches an NLP response then updates the UI */
  async submit(event) {
    const passage = readValue("passage-input");
    const question = readValue("question-input");
    this.loading = true;
    this.error = false;
    const answer = await fetchQuestion({ passage, question })
      // take the text of the first result
      .then(([{ text }]) => text)
      // Side effect: update the database with { answer, passage, question };
      .then(updateDb({ passage, question }))
      // Side effect: update document
      .then(this.fireSubmitEvent)
      .catch(handleError);
    this.loading = false;
    this.error = true;
    return answer;
  }

  /* Displays the response in the document. */
  fireSubmitEvent({ answer }) {
    this.dispatchEvent(new CustomEvent("answer-submitted", { detail: answer }));
  }

  async reset() {
    await this.updateComplete;
    this.$("question-input").value = "";
    this.error = false;
  }
}

customElements.define(DeardiaryQuestion.is, DeardiaryQuestion);
