import { LitElement, html, css } from 'lit';
import popupStyles from '../shared-styles/popup-styles.css';

class DeardiaryPopup extends LitElement {
  static get is() {
    return 'deardiary-popup';
  }

  static get properties() {
    return {
      opened: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      popupStyles,
      css`
      /* The Modal (background) */
      :host {
        color: black;
        background-color: rgba(0,0,0,0.4);
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        -webkit-animation-name: fadeIn; /* Fade in the background */
        -webkit-animation-duration: 0.4s;
        animation-name: fadeIn;
        animation-duration: 0.4s;
        --chevron-radius: 12px;
        --chevron-width: calc(2 * var(--chevron-radius));

      }

      #modal-content::after {
        content: ' ';
        display: block;
        width: var(--chevron-width);
        height: var(--chevron-width);
        position: absolute;
        left: calc(50% - var(--chevron-radius));
        bottom: -12px;
        transform: rotate(45deg);
        z-index: 1;
        background: white;
      }

      :host([opened]) {
        display: block;
      }

      #modal-body {
        padding: 2px 16px;
      }

      /* Modal Content */
      #modal-content {
        position: fixed;
        bottom: 0;
        background-color: #ffffff;
        width: 100%;
        -webkit-animation-name: slideIn;
        -webkit-animation-duration: 0.4s;
        animation-name: slideIn;
        animation-duration: 0.4s;
        flex-flow: column;
        justify-content: end;
        transform: translateY(calc(-1 * var(--chevron-radius)))
      }

      /* The Close Button */
      #close-button {
        background: none;
        padding: 0 20px;
        color: black;
        font-size: 28px;
        font-weight: bold;
        float: right;
      }

      #close-button:not(:focus) {
        border: none;
      }

      #close-button:hover,
      #close-button:focus {
        text-decoration: none;
        cursor: pointer;
      }

      /* Add Animation */
      @-webkit-keyframes slideIn {
        from {bottom: -300px; opacity: 0}
        to {bottom: 0; opacity: 1}
      }

      @keyframes slideIn {
        from {bottom: -300px; opacity: 0}
        to {bottom: 0; opacity: 1}
      }

      @-webkit-keyframes fadeIn {
        from {opacity: 0}
        to {opacity: 1}
      }

      @keyframes fadeIn {
        from {opacity: 0}
        to {opacity: 1}
      }

      `,
    ];
  }

  constructor() {
    super();
    this.onBodyClick = this.onBodyClick.bind(this);
  }

  updated(changed) {
    if (this.opened) {
      document.body.addEventListener('click', this.onBodyClick);
    } else {
      document.body.removeEventListener('click', this.onBodyClick);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.body.removeEventListener('click', this.onBodyClick);
  }

  // When the user clicks anywhere outside of the modal, close it
  onBodyClick(event) {
    if (!event.composedPath().includes(this)) this.close();
  }

  render() {
    return html`
      <div id="modal-content">
        <button id="close-button"
            tabindex="${this.opened ? 0 : -1}"
            @click="${this.close}">
          &times;
        </button>
        <div id="modal-body">
          <slot></slot>
        </div>
      </div>
    `;
  }

  close() {
    this.opened = false;
  }

  open() {
    this.opened = true;
  }
}

customElements.define(DeardiaryPopup.is, DeardiaryPopup);
