import { LitElement, html, css } from 'lit';
import { downloadJson, readFileListAsJson } from '../lib/files.js';
import { getAllEntries, writeMultipleEntries } from '../lib/db.js';
import map from 'crocks/pointfree/map';

import { PageMixin } from './page-mixin';
import buttonLinkStyles from '../shared-styles/button-link.css';
import pageStyles from '../shared-styles/page-styles.css';

class DeardiaryArchivefiles extends PageMixin(LitElement) {
  static get is() {
    return 'deardiary-archivefiles';
  }

  static get properties() {
    return {
    };
  }

  static get styles() {
    return [
      pageStyles,
      buttonLinkStyles,
      css`
        input {
          display: none;
        }

        label {
          font-family: 'Comfortaa', cursive;
          font-display: auto;
          background-color: #000000;
          color: #ffffff;
          font-size: 1.3em;
          padding: 0.2em;
          margin: 1em;
          border: none;
        }
      `,
    ];
  }

  render() {
    return html`
      <h1>Backup Your Archive</h1>

      <p>
        Backup all of your writing - including the machine's answers to your questions - in a JSON file.
        In addition to being a backup for deardiary,
        this timestamped file can be used for a variety of online and offline purposes.
      </p>

      <button id="archivefiles"
          class="button-link"
          @click="${this.exportBackup}"
      >Get Backup</button>

      <br/>

      <h1>Restore Your Archive</h1>

      <p>
        The cache is hidden and keeps all of your data.
        Restore your archive using your JSON backups in the event your cache is cleared.
      </p>

      <label class="button-link" for="file-picker">Restore Archive</label>

      <input id="file-picker"
          name="files[]"
          @change="${this.importBackup}"
          type="file"
          accept="application/json"
      />

      <a href="/" class="button-link">Home</a>
    `;
  }

  onActiveChanged(active) {
    if (active) this.getEntries();
  }

  async exportBackup() {
    downloadJson(`deardiary backup ${new Date()}.json`, await getAllEntries());
  }

  async importBackup({ target: { files } }) {
    return readFileListAsJson(files)
      .then(map(writeMultipleEntries));
  }
}

customElements.define(DeardiaryArchivefiles.is, DeardiaryArchivefiles);
