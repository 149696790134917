import { LitElement, html, css } from 'lit';
import { PageMixin } from './page-mixin';
import buttonLinkStyles from '../shared-styles/button-link.css';
import pageStyles from '../shared-styles/page-styles.css';
import { readValue, writeValue } from '../storage.js';

class DeardiaryWrite extends PageMixin(LitElement) {
  static get is() {
    return 'deardiary-write';
  }

  static get properties() {
    return {
      passage: { type: String },
    };
  }

  static get styles() {
    return [
      pageStyles,
      buttonLinkStyles,
      css`
        #passage-input::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #000000;
        }

        #passage-input::-webkit-scrollbar {
          width: 10px;
          background-color: #f5f5f5;
        }

        #passage-input::-webkit-scrollbar-thumb {
          background-color: #000000;
          border: 2px solid #555555;
        }
      `,
    ];
  }

  render() {
    return html`
      <h1 id="passage-heading">Write about the feelings here.</h1>

      <textarea
        id="passage-input"
        ?disabled="${!this.active}"
        placeholder="Tap to write here. The more you write, the more you'll leave behind. The answer is only as good as your story."
        aria-labelledby="passage-heading"
        name="passage"
        rows="12"
        .value="${this.passage || ''}"
        @keyup="${this.onKeyup}"
      ></textarea>

      <a class="button-link" href="/write/question">Now, Ask a Question</a>
    `;
  }

  onActiveChanged(active) {
    if (!active) return this.$('passage-input').blur();
    this.passage = readValue('passage-input');
    this.$('passage-input').focus();
  }

  onKeyup(event) {
    writeValue('passage-input', event.target.value);
  }

  async reset() {
    await this.updateComplete;
    this.$('passage-input').value = '';
  }
}

customElements.define(DeardiaryWrite.is, DeardiaryWrite);
