// qna import
import * as qna from '@tensorflow-models/qna';
// import '@tensorflow/tfjs-core';
// import '@tensorflow/tfjs-backend-cpu';
// import '@tensorflow/tfjs-backend-webgl';

export async function runQna(question, passage) {
  // Load the model
  const model = await qna.load();

  // Finding the answers
  const answers = await model.findAnswers(question, passage);

  // console.log('Answers: ', answers);

  return answers;
  /// //////// end qna code //////////
}
