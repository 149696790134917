import { LitElement, html, css } from 'lit';
import { PageMixin } from './page-mixin';
import buttonLinkStyles from '../shared-styles/button-link.css';
import pageStyles from '../shared-styles/page-styles.css';
import { readValue, writeValue } from '../storage.js';

class DeardiaryAnswer extends PageMixin(LitElement) {
  static get is() {
    return 'deardiary-answer';
  }

  static get properties() {
    return {
      answer: { type: String },
    };
  }

  static get styles() {
    return [
      pageStyles,
      buttonLinkStyles,
      css`
        img {
          display: block;
          margin: auto;
        }

        p {
          text-align: center;
        }
      `,
    ];
  }

  render() {
    const { answer, resetQuestion, resetWrite } = this;
    return html`
      <p id="answer" ?hidden="${!answer}">${answer}</p>
      <img ?hidden="${answer}" src="/images/loaders/ellipsis_loader.svg" />
      <p ?hidden="${answer}">
        More information needed. Please go back and write more.
      </p>
      <a href="/write/question" class="button-link" @click="${resetQuestion}"
        >Ask a different question</a
      >
      <a href="/write" class="button-link" @click="${resetWrite}"
        >Write a different story</a
      >
      <a href="/" class="button-link" @click="${resetWrite}">Home</a>
    `;
  }

  onActiveChanged(active) {
    if (!active) return;
    this.answer = readValue('answer');
  }

  resetQuestion() {
    this.dispatchEvent(new CustomEvent('reset-question'));
    writeValue('question-input', '');
    writeValue('answer', '');
  }

  resetWrite() {
    this.resetQuestion();
    this.dispatchEvent(new CustomEvent('reset-passage'));
    writeValue('passage-input', '');
  }
}

customElements.define(DeardiaryAnswer.is, DeardiaryAnswer);
